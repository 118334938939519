import $ from 'jquery'
import { ready } from 'shared/ready'

export class Collapse {
  constructor($root, config) {
    this.config = {
      itemClass: 'collapse__item',
      bodyClass: 'collapse__body',
      toggleClass: 'collapse__toggle',
      onOpen: () => {},
      ...(config || {}),
    }
    this.$root = $root
    this.$items = $root.find(`.${this.config.itemClass}`)
    this.isOpenClass = 'is-open'
    this.isAnimating = false

    this.init()
  }

  init = () => {
    // Remove any existing click handlers
    this.$root.find(`.${this.config.toggleClass}`).off('click')

    // Bind click handlers once
    this.$root.on('click', `.${this.config.toggleClass}`, e => {
      // Prevents the event from bubbling up the DOM tree, preventing any parent
      // handlers from being notified of the event.
      e.stopPropagation()

      const $item = $(e.currentTarget).closest(`.${this.config.itemClass}`)

      // Prevent double clicks during animation
      if (this.isAnimating) {
        return
      }

      if (!$item.hasClass(this.isOpenClass)) {
        this.open($item)
      } else {
        this.close($item)
      }
    })
  }

  open = $item => {
    const $body = $item.find(`.${this.config.bodyClass}`)
    this.changeToggleText($item)
    this.isAnimating = true

    if (this.config.isAccordion) {
      this.$items.filter(`.${this.isOpenClass}`).each((_, el) => this.close($(el)))
    }

    $item.addClass(this.isOpenClass)
    $body.css({ height: 0, opacity: 0, overflow: 'hidden' })
    $body.animate(
      {
        height: $body.get(0).scrollHeight,
        opacity: 1,
      },
      {
        queue: false,
        duration: 300,
        complete: () => {
          $body.css({ overflow: '', height: 'auto' })
          this.isAnimating = false
          this.config.onOpen()
        },
      },
    )
  }

  close = $item => {
    const $body = $item.find(`.${this.config.bodyClass}`)
    this.changeToggleText($item)
    this.isAnimating = true

    $item.removeClass(this.isOpenClass)
    $body.css({ height: $body.get(0).scrollHeight, opacity: 1, overflow: 'hidden' })
    $body.animate(
      {
        height: 0,
        opacity: 0,
      },
      {
        queue: false,
        duration: 300,
        complete: () => {
          this.isAnimating = false
        },
      },
    )
  }

  changeToggleText = $item => {
    const $toggle = $item.find(`.${this.config.toggleClass}`)
    const altText = $toggle.data('alt-text')

    if (altText) {
      const $toggleText = $toggle.find('span')
      const currentText = $toggleText.text()

      $toggle.data('alt-text', currentText)
      $toggleText.text(altText)
    }
  }

  destroy = () => {
    this.$root.find(`.${this.config.toggleClass}`).off('click')
    this.$root.off('click', `.${this.config.toggleClass}`)
  }
}

ready(function () {
  $('.collapse').each((_, el) => new Collapse($(el)))
})
