import $ from 'jquery'
import { ready } from 'shared/ready'
import { trackInternalEvent } from '@rio/tracking'

class TemplatesRoot {
  constructor($root) {
    if ($root.length === 0) return

    this.$root = $root

    this.$filter = $root.find('.templates-filter')
    this.$tabs = this.$filter.find('.templates-filter__tab')
    this.$selector = this.$filter.find('.templates-filter__tabs-selector')
    this.$grid = $root.find('.templates-grid')
    this.$cells = $root.find('.templates-grid__cell')

    this.$headerTitle = $root.find('.templates-root__header-title')
    this.$headerSubtitle = $root.find('.templates-root__header-subtitle')
    this.$post = $root.find('.blog-post')
    this.$content = this.$post.find('.blog-post__content-main-wrapper')

    this.infoUrl = this.$root.data('info-url')
    this.activeTagName = $root.data('current-tag')
    this.tags = this.serializeTags()
    this.isAnimated = false

    this.$tabs.on('click', this.handleTabClick)

    $(window).on('load resize', () => {
      this.moveSelectorTo(this.activeTagName)
      this.handleRightCtaHeight()
    })
  }

  serializeTags = () => {
    const data = {}

    this.$tabs.each((i, el) => {
      const $tab = $(el)
      const tagName = $tab.data('tag')
      const templatesOrder = $tab.data('templates-order').split(',')

      const $cells = this.$cells.filter(function () {
        return templatesOrder.includes($(this).data('name'))
      })

      data[tagName] = { $tab, $cells, templatesOrder }
    })

    return data
  }

  handleTabClick = event => {
    event.preventDefault()
    const tagName = $(event.target).data('tag')
    const tagTitle = $(event.target).text()
    trackInternalEvent('change_resume_templates_category', { tab: tagName })
    this.showTag(tagName)
    this.handleBreadcrumb(tagName, tagTitle)
  }

  toggleTabState = (tagName, state) => {
    const tag = this.tags[tagName]
    tag.$tab.toggleClass('templates-filter__tab--active', state)
  }

  showTag = tagName => {
    if (tagName === this.activeTagName || this.isAnimated) return
    this.isAnimated = true

    // trigger fade out transition
    this.$grid.addClass('templates-grid--hidden')

    this.toggleTabState(this.activeTagName, false)
    this.activeTagName = tagName
    this.toggleTabState(tagName, true)
    this.moveSelectorTo(tagName)
    this.loadTagContent(tagName)

    // show the grid when the fade out transition is complete
    setTimeout(() => {
      // show and hide cells while the list is hidden
      this.$cells.addClass('templates-grid__cell--hidden')
      this.tags[tagName].$cells.removeClass('templates-grid__cell--hidden')
      // reorder templates according to `data-templates-order` attribute value
      this.sortTemplates(tagName)
      // trigger fade in transition
      this.$grid.removeClass('templates-grid--hidden')
      // unlock tab navigation
      this.isAnimated = false
    }, 300)

    // modify the URL without reloading the page
    const url = this.tags[tagName].$tab.attr('href')
    window.history.replaceState({}, '', url)
  }

  handleBreadcrumb = (tagName, tagTitle) => {
    const $breadcrumb = $('.templates-root__breadcrumbs')
    const count = $breadcrumb.find('li').length
    if (tagName === 'all') {
      if (count > 2) $breadcrumb.find('li:last-child').remove()
    } else {
      if (count > 2) {
        $breadcrumb.find('li:last-child').remove()
      }
      const $newBreadcrumb = $(
        `<li class="templates-root__breadcrumbs-item" itemprop="itemListElement" itemscope="" itemtype="http://schema.org/ListItem">
          <span class="templates-root__breadcrumbs-label" itemprop="name">${tagTitle}</span>
        </li>`,
      )
      $breadcrumb.append($newBreadcrumb)
    }
  }

  sortTemplates = tagName => {
    this.tags[tagName].templatesOrder.forEach(name => {
      const $cell = this.$grid.find(`[data-name="${name}"]`)
      $cell.appendTo(this.$grid)
    })
  }

  moveSelectorTo = tagName => {
    const $tab = this.tags[tagName].$tab
    const { left } = $tab.position()
    const width = $tab.width()

    // move the line using `transform` instead of `width` for better performance
    this.$selector.css('transform', `translateX(${left}px) scaleX(${width / 100})`)
    this.$selector.removeClass('templates-filter__tabs-selector--hidden')
  }

  handleRightCtaHeight = () => {
    let removeCtaBannerTemplates = $('#remove-cta-banner-value_templates').text()
    if (removeCtaBannerTemplates) {
      removeCtaBannerTemplates = removeCtaBannerTemplates === 'true'
      trackInternalEvent('cta_banner_templates', { saw_cta_banner: !removeCtaBannerTemplates })
      window.addEventListener('scroll', this.handleScroll(removeCtaBannerTemplates))
    }
  }

  renderTagContent = tagName => {
    // do not render if the tag isn't active
    if (tagName !== this.activeTagName) return

    const tag = this.tags[tagName]

    // display tag texts
    this.$headerTitle.text(tag.headerTitle)
    this.$headerSubtitle.text(tag.headerSubtitle)
    this.$content.html(tag.content || '')

    // hide the post wrapper and sidebar if there is no content to display
    this.$post.css('display', tag.content ? 'block' : 'none')
    let removeCtaBannerTemplates = $('#remove-cta-banner-value_templates').text()
    if (removeCtaBannerTemplates) {
      removeCtaBannerTemplates = removeCtaBannerTemplates === 'true'
      trackInternalEvent('cta_banner_templates', { saw_cta_banner: !removeCtaBannerTemplates })
      window.addEventListener('scroll', this.handleScroll(removeCtaBannerTemplates))
    }
  }

  handleScroll(removeCtaBannerTemplates) {
    const postBannerCta = document.getElementById('post-banner-cta')
    if (postBannerCta && removeCtaBannerTemplates === true) {
      const mainContentHeight = $('.blog-post__content-main-wrapper').height()
      let rightPanelHeight = mainContentHeight * (70 / 100)
      $('#blog-post-banner-content-right').css({ height: rightPanelHeight })
    }
  }

  loadTagContent = tagName => {
    const tag = this.tags[tagName]

    // do not load data again
    if (tag.loaded) return this.renderTagContent(tagName)

    // load tag data (title, rich text description, etc)
    $.get(this.infoUrl, { tag: tagName }).done(result => {
      Object.assign(tag, {
        loaded: true,
        headerTitle: result.title,
        headerSubtitle: result.subtitle,
        content: result.content,
      })
      this.renderTagContent(tagName)
    })
  }
}

// ---
// Init
// ---
ready(function () {
  new TemplatesRoot($('.templates-root'))
})
