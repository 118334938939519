import modals from '../../support/modals'
import { trackInternalEvent } from '@rio/tracking'

class CookieSettingsForm {
  constructor($form, cookieController, cookiePanel) {
    this.$form = $form
    this.cookieController = cookieController
    this.cookiePanel = cookiePanel
    this.$acceptAllCookiesButton = this.$form.find('.cookie-settings-modal__accept')
    this.$rejectAllCookiesButton = this.$form.find('.cookie-settings-modal__reject')
    this.$analyticalCheckbox = this.$form.find('#AnalyticalCookies')
    this.$marketingCheckbox = this.$form.find('#MarketingCookies')

    this.$form.on('submit', this.handleSubmit)

    this.$acceptAllCookiesButton.on('click', this.handleClickAcceptAllButton)
    this.$rejectAllCookiesButton.on('click', this.handleClickRejectButton)
  }

  handleSubmit = e => {
    e.preventDefault()
    const events = this.$form.serializeArray().map(item => item.value)

    if (this.cookieController) {
      this.cookieController.acceptCookies(events)
    }

    this.trackCookiePreferences()
    this.closeCookieControls()
  }

  handleClickAcceptAllButton = () => {
    if (this.cookieController) {
      this.cookieController.acceptAllCookies()
    }

    trackInternalEvent('click_cookie_banner', {
      cta_text: 'Accept all',
      cookie_settings: 'Accept all',
    })

    this.closeCookieControls()
  }

  handleClickRejectButton = () => {
    if (this.cookieController) {
      this.cookieController.rejectAllCookies()
    }

    trackInternalEvent('click_cookie_banner', {
      cta_text: 'Reject all',
      cookie_settings: 'Reject',
    })

    this.closeCookieControls()
  }

  trackCookiePreferences = () => {
    if (this.$analyticalCheckbox.is(':checked')) {
      trackInternalEvent('click_cookie_banner', {
        cta_text: 'Save',
        cookie_settings: 'Analytical cookies',
      })
    } else if (this.$marketingCheckbox.is(':checked')) {
      trackInternalEvent('click_cookie_banner', {
        cta_text: 'Save',
        cookie_settings: 'Marketing cookies',
      })
    } else {
      trackInternalEvent('click_cookie_banner', {
        cta_text: 'Save',
        cookie_settings: 'Essential only',
      })
    }
  }

  closeCookieControls = () => {
    if (modals.cookieSettingsModal) {
      modals.cookieSettingsModal.close()
    }

    if (this.cookiePanel) {
      this.cookiePanel.close()
    }
  }
}

export default CookieSettingsForm
